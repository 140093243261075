/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import examType1Actions from '../../../../../../../redux/actions/examType1Actions';

const useStyles = makeStyles(() => ({
  button: {
    marginTop: '2em',
  },
}));

const NextButton = ({
  pushAnswer, currentAnswer, currentQuestion, setCurrentQuestion,
}) => {
  const classes = useStyles();

  const handleNext = () => {
    pushAnswer();
    setCurrentQuestion(currentQuestion + 1);
  };

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={handleNext}
      disabled={currentAnswer && currentAnswer.length === 0}
      className={classes.button}
      fullWidth
    >
      Submit
    </Button>
  );
};

NextButton.propTypes = {
  currentQuestion: PropTypes.number.isRequired,
  currentAnswer: PropTypes.array.isRequired,
  setCurrentQuestion: PropTypes.func.isRequired,
  pushAnswer: PropTypes.func.isRequired,
};

const actionCreators = {
  setCurrentQuestion: examType1Actions.setCurrentQuestion,
  pushAnswer: examType1Actions.pushAnswer,
};

function mapState(state) {
  const { currentQuestion, currentAnswer } = state.exam;
  return {
    currentQuestion, currentAnswer,
  };
}
export default connect(mapState, actionCreators)(NextButton);
