/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable object-curly-newline */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  Grid, Typography, Button, CircularProgress, Snackbar,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import examType1Services from '../../../../../../../redux/services/examType1Services';
import examType1Actions from '../../../../../../../redux/actions/examType1Actions';
import config from '../../../../../../../config';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
    position: 'relative',
  },
  mainTitle: {
    marginTop: '1em',
    textAlign: 'center',
  },
  loadingText: {
    marginTop: '1em',
    textAlign: 'center',
    marginBottom: '30px',
  },
  button: {
    display: 'block',
    margin: '50px auto 50px auto',
    maxWidth: '260px',
    textAlign: 'center',
  },
  image: {
    width: '35%',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

const TestFinished = ({ testId, answers, clearTest, setFinished }) => {
  const classes = useStyles();
  const [scores, setScores] = useState(null);
  const [errorSnackBar, setErrorSnackBar] = useState(null);

  useEffect(() => {
    let isCancelled = false;
    const body = {
      answers,
      time: 0,
      isFinished: true,
    };
    if (!isCancelled) {
      examType1Services.sendTest(testId, body)
        .then(
          (res) => {
            if (res.test) {
              setScores(res.test.examScores);
            }
          },
          (error) => {
            if (!isCancelled) {
              console.error(error);
            }
          },
        )
        .catch(() => {
          setErrorSnackBar({ code: 1701, message: 'Problem sending results.', severity: 'error' });
        });
    }
    return () => {
      isCancelled = true;
    };
  }, []);

  const endExam = () => {
    setFinished(true);
    clearTest();
  };

  return scores ? (
    <Grid container direction="row" className={classes.root} justify="center">
      <Snackbar open={errorSnackBar !== null} onClose={() => { setErrorSnackBar(null); }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert severity={errorSnackBar !== null ? errorSnackBar.severity : 'info'} variant="filled" onClose={() => { setErrorSnackBar(null); }}>
          {`Error ${errorSnackBar?.code}: ${errorSnackBar?.message}`}
        </Alert>
      </Snackbar>
      <Grid item md={12}>
        <Typography className={classes.mainTitle} variant="h5">
          Vous avez répondu à toutes les questions.
        </Typography>
        <Grid item md={12}>
          <img className={classes.image} alt="placement test illustration" src={`${config.OBJECT_URL}/thumbnail/end-test.svg`} />
        </Grid>
      </Grid>
      <Button
        className={classes.button}
        color="primary"
        variant="contained"
        onClick={() => endExam()}
      >
        Cliquer ici pour envoyer vos résultats
      </Button>
    </Grid>
  ) : (
    <Grid container direction="column" className={classes.root} justify="center" alignItems="center">
      <Typography className={classes.loadingText} variant="h5">
        Chargement en cours
      </Typography>
      <CircularProgress />
    </Grid>
  );
};

TestFinished.propTypes = {
  testId: PropTypes.string.isRequired,
  answers: PropTypes.array.isRequired,
  setFinished: PropTypes.func.isRequired,
  clearTest: PropTypes.func.isRequired,
};

const actionCreators = {
  clearTest: examType1Actions.clearTest,
};

function mapState(state) {
  const { userData } = state.user;
  const { semester, company, _id } = userData;
  return {
    semester, company, _id,
  };
}
export default connect(mapState, actionCreators)(TestFinished);
