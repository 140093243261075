/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Iframe from 'react-iframe';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import config from '../../../../config';
import moduleServices from '../../../../redux/services/moduleServices';

const useStyles = makeStyles(() => ({
  iframe: {
    border: '0',
    width: '100%',
    boxShadow: '10px 10px 20px rgba(0, 0, 0, 0.05), 0 6px 6px rgba(0, 0, 0, 0.05) !important',
    backgroundColor: '#fff',
  },
  rise: {
    height: '100rem',
  },
  storyline: {
    height: '96%',
  },
}));

const StorylineContent = ({
  filename, launchPoint, userName, email, activityId, rise, userData, group, currentGroup,
}) => {
  const classes = useStyles();
  const [inProgress, setInProgress] = useState(0);
  const [errorSnackBar, setErrorSnackBar] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setInProgress(0);
    if (userData !== undefined && group.data[currentGroup]._id.length > 0) {
      moduleServices.globalResultsTest(userData._id, userData.email, group.data[currentGroup]._id)
        .then((results) => {
          if (results && results.length > 0) {
            results[0].forEach((module) => {
              module.units.forEach((unit) => {
                if (unit.unit.activityId.toString() === activityId && unit.unit.time && inProgress >= 0) {
                  if (!unit.unit.activityId.includes('rise')) {
                    setInProgress(unit.unit.time);
                  }
                }
              });
            });
          }
        })
        .catch(() => {
          setErrorSnackBar({ code: 2301, message: 'Missing global results.', severity: 'warning' });
        });
    }
  }, [userData, group.data[currentGroup]._id, activityId]);

  useEffect(() => {
    if (inProgress > 0) {
      setOpen(true);
    }
  }, [inProgress, activityId]);

  return (
    <>
      <Snackbar open={open} onClose={() => { setOpen(false); }} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
        <Alert severity="info" variant="filled" onClose={() => { setOpen(false); }}>
          Pour recommencer/réinitialiser une unité, cliquez sur `Restart` au lieu de `Continue`
        </Alert>
      </Snackbar>
      <Snackbar open={errorSnackBar !== null} onClose={() => { setErrorSnackBar(null); }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert severity={errorSnackBar !== null ? errorSnackBar.severity : 'info'} variant="filled" onClose={() => { setErrorSnackBar(null); }}>
          {`Error ${errorSnackBar?.code}: ${errorSnackBar?.message}`}
        </Alert>
      </Snackbar>
      <Iframe
        loading="Loading..."
        className={`${classes.iframe} ${rise ? classes.rise : classes.storyline}`}
        url={`${config.OBJECT_URL}/content/${filename}/${launchPoint}?endpoint=${config.LRS_ENDPOINT}&actor={%22name%22:%20[%22${encodeURI(userName)}%22],%20%22mbox%22:%20[%22mailto:${encodeURI(email.trim())}%22]}&activity_id=http://${activityId}&tincan=true`}
        title="StorylineFrame"
      />
    </>
  );
};

StorylineContent.propTypes = {
  filename: PropTypes.string.isRequired,
  launchPoint: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  activityId: PropTypes.string.isRequired,
  rise: PropTypes.string.isRequired,
  userData: PropTypes.object.isRequired,
  group: PropTypes.object.isRequired,
  currentGroup: PropTypes.number.isRequired,
};

function mapState(state) {
  const { group } = state;
  const { userData } = state.user;
  const { currentGroup } = state.ui;

  return {
    userData,
    currentGroup,
    group,
  };
}
export default withRouter(connect(mapState, null)(StorylineContent));
