/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Container } from '@material-ui/core';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';

import Layout from '../Layout/index';
import Login from '../Login/index';
import PlacementTest from '../PlacementTest/index';
import Dashboard from '../Dashboard/index';
import Videos from '../Videos/index';
import Contact from '../Contact/index';
import Glossary from '../Glossary/index';
import LearningLinks from '../LearningLinks/index';

import Courses from '../Elearning/components/content/index';
import Module from '../Elearning/components/content/Module';
import Results from '../Elearning/components/results/index';
import Resources from '../Elearning/components/resources/index';
import Exam from '../Elearning/components/content/Exam/index';

import DisplayThemes from '../LearningLinks/components/DisplayThemes';
import DisplayTopics from '../LearningLinks/components/DisplayTopics';
import DisplayTopicLinks from '../LearningLinks/components/DisplayTopicLinks';
import Profile from '../Profile';
import Group from '../Group';

const userIsNotAuthenticated = connectedRouterRedirect({
  redirectPath: '/dashboard',
  allowRedirectBack: false,
  authenticatedSelector: (state) => state.auth.loggedIn !== true,
  wrapperDisplayName: 'UserIsNotAuthenticated',
});

const userIsAuthenticated = connectedRouterRedirect({
  redirectPath: '/',
  allowRedirectBack: false,
  authenticatedSelector: (state) => state.auth.loggedIn === true,
  wrapperDisplayName: 'UserIsAuthenticated',
});

const wrappedLearningLinksRoutes = () => (
  <>
    <LearningLinks />
    <Container>
      <Switch>
        <Route exact path="/learning-links" component={DisplayThemes} />
        <Route exact path="/learning-links/:themeName" component={DisplayTopics} />
        <Route exact path="/learning-links/:themeName/:topicName" component={DisplayTopicLinks} />
      </Switch>
    </Container>
  </>
);

const wrappedElearningRoutes = () => (
  <div>
    <Switch>
      <Route exact path="/elearning/course" component={Courses} />
      <Route exact path="/elearning/course/:moduleName" component={Module} />
      <Route exact path="/elearning/results" component={Results} />
      <Route exact path="/elearning/resources" component={Resources} />
      <Route exact path="/elearning/exam" component={Exam} />
      {/* <Route exact path="/elearning/exam/results" component={ExamType1Results} /> */}
    </Switch>
  </div>
);

const WrappedRoutes = () => (
  <div>
    <Layout>
      <Switch>
        <Route path="/test/" component={PlacementTest} />
        <Route path="/dashboard/" component={Dashboard} />
        <Route path="/glossary/" component={Glossary} />
        <Route path="/group/" component={Group} />
        <Route path="/profile" component={Profile} />
        <Route path="/elearning/" component={wrappedElearningRoutes} />
        <Route path="/learning-links/" component={wrappedLearningLinksRoutes} />
        <Route path="/videos/" component={Videos} />
        <Route path="/help" component={Contact} />
      </Switch>
    </Layout>
  </div>
);

const Router = () => (
  <Switch>
    <Route exact path="/" component={userIsNotAuthenticated(Login)} />
    <Route path="/" component={userIsAuthenticated(WrappedRoutes)} />
  </Switch>
);

export default Router;
