/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import userActions from '../../../redux/actions/userActions';
import uiActions from '../../../redux/actions/uiActions';

const ProfileMenu = ({
  anchorEl, logout, changeDarkMode, handleMenuClose, changeDictionnary, darkMode, dictionnary,
}) => {
  const profilMenuId = 'account-menu';
  const [menuRedirect, setmenuRedirect] = useState(false);

  useEffect(() => {
    setmenuRedirect(false);
  }, [menuRedirect]);

  function goToProfile() {
    setmenuRedirect(true);
    handleMenuClose();
  }

  return (
    <Menu
      anchorEl={anchorEl}
      id={profilMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={() => goToProfile()}>Profil</MenuItem>
      {
        menuRedirect && <Redirect to="/profile" />
      }
      <div style={{ width: '100%' }}>
        <FormControlLabel
          value="start"
          style={{ paddingRight: '10px' }}
          control={<Switch onClick={() => changeDarkMode(!darkMode)} checked={darkMode} color="primary" />}
          label="Mode sombre"
          labelPlacement="start"
        />
      </div>
      <FormControlLabel
        value="start"
        style={{ paddingRight: '10px' }}
        control={<Switch onClick={() => changeDictionnary(!dictionnary)} checked={dictionnary} color="primary" />}
        label="Dictionnary"
        labelPlacement="start"
      />
      <Divider />
      {/* <MenuItem>Mon profil</MenuItem> */}
      <MenuItem onClick={() => logout()}>Se deconnecter</MenuItem>
    </Menu>
  );
};

ProfileMenu.defaultProps = {
  anchorEl: null,
};

ProfileMenu.propTypes = {
  anchorEl: PropTypes.any,
  logout: PropTypes.func.isRequired,
  changeDarkMode: PropTypes.func.isRequired,
  changeDictionnary: PropTypes.func.isRequired,
  handleMenuClose: PropTypes.func.isRequired,
  darkMode: PropTypes.bool.isRequired,
  dictionnary: PropTypes.bool.isRequired,
};

function mapState(state) {
  const { currentPage, darkMode, dictionnary } = state.ui;
  return {
    currentPage, darkMode, dictionnary,
  };
}

const actionCreator = {
  logout: userActions.logout,
  changeDarkMode: uiActions.changeDarkMode,
  changeDictionnary: uiActions.changeDictionnary,
};

export default connect(mapState, actionCreator)(ProfileMenu);
