/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import {
  apiClient,
  handleResponse,
} from './axios';

async function uploadFile(data) {
  try {
    const res = await apiClient.post('/upload', data);
    return res.data.message;
  } catch (error) {
    return error.response.data;
  }
}

async function checkFile(data) {
  try {
    const res = await apiClient.post('/getfile', data);
    return res;
  } catch (error) {
    return error.response.data;
  }
}

const fileServices = {
  uploadFile,
  checkFile,
};

export default fileServices;
