/* eslint-disable no-console */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Dialog, DialogTitle, Button, Typography, DialogContent,
} from '@material-ui/core';
import userService from '../../../redux/services/userServices';
import config from '../../../config';

const SatisfactionDialog = ({
  open, setOpen, userId, getUser,
}) => {
  const goToSatisfactionForm = () => {
    userService.updateSatisfaction(userId)
      .then(() => {
        setOpen(false);
        getUser(userId);
        window.open('https://englishworld1.typeform.com/to/A0bhZIib', '_blank', 'noopener,noreferrer');
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogTitle style={{
        backgroundColor: config.primaryColor, color: 'white',
      }}
      >
        <Typography style={{ fontWeight: 'bold', fontSize: 20 }}>
          Votre avis nous intéresse !
        </Typography>
      </DialogTitle>
      <DialogContent style={{
        display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end',
      }}
      >
        <Typography style={{ width: '90%', margin: '5%' }}>
          Merci de répondre à notre enquête de satisfaction (3 minutes).
        </Typography>
        <Button
          onClick={() => goToSatisfactionForm()}
          autoFocus
          style={{
            backgroundColor: config.primaryColor, color: 'white', paddingLeft: 20, paddingRight: 20, marginBottom: 15, fontWeight: 'bold',
          }}
        >
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          Je réponds à l'enquête
        </Button>
      </DialogContent>
    </Dialog>
  );
};

function mapState(state) {
  const { authData } = state.auth;
  const { _id } = authData;
  return {
    userId: _id,
  };
}

SatisfactionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  getUser: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default connect(mapState, null)(SatisfactionDialog);
