/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
import examType1Constants from '../constants/examType1Constants';
import examType1Services from '../services/examType1Services';

function initTest(id, body) {
  function request() {
    return {
      type: examType1Constants.INIT_REQUEST,
    };
  }

  function success(exam) {
    return {
      type: examType1Constants.INIT_SUCCESS,
      exam,
    };
  }

  function failure(error) {
    return {
      type: examType1Constants.INIT_FAILURE,
      error,
    };
  }

  return (dispatch) => {
    dispatch(request());
    examType1Services.initTest(id, body)
      .then(
        (exam) => {
          localStorage.setItem('exam', JSON.stringify(exam));
          dispatch(success(exam));
        },
        (error) => {
          dispatch(failure(error ? error.toString() : 'error'));
        },
      )
      .catch((e) => dispatch(failure(e ? e.toString() : 'error')));
  };
}

const setCurrentQuestion = (currentQuestion) => {
  localStorage.setItem('currentQuestion', JSON.stringify(currentQuestion));
  return ({
    type: examType1Constants.SET_CURRENT_QUESTION,
    currentQuestion,
  });
};

export const checkExamBeginTime = (additionalTime, duration) => {
  const time = new Date();
  const savedDate = localStorage.getItem('examBeginTime');
  const oldDate = new Date();
  oldDate.setFullYear(oldDate.getFullYear() - 1);
  let examBeginTime = new Date(JSON.parse(savedDate));
  if (examBeginTime.getTime() < oldDate.getTime()) {
    examBeginTime = new Date();
    examBeginTime.setTime(examBeginTime.getTime());
  }
  examBeginTime.setTime(examBeginTime.getTime() + (additionalTime ? additionalTime * 60 * 1000 : 0));
  examBeginTime.setTime(examBeginTime.getTime() + (60 * 1000 * duration));
  if (examBeginTime < time) {
    return false;
  }
  return (examBeginTime.getTime() - time.getTime());
};

const setExamBeginTime = () => {
  const time = new Date();
  // time.setHours(time.getHours());
  localStorage.setItem('examBeginTime', JSON.stringify(time));
  return ({
    type: examType1Constants.SET_EXAM_BEGIN_TIME,
    time,
  });
};

const setCurrentAnswer = (currentAnswer) => ({
  type: examType1Constants.SET_CURRENT_ANSWER,
  currentAnswer,
});

const setMultiQuestionId = (id) => ({
  type: examType1Constants.SET_MULTI_QUESTION_ID,
  id,
});

const pushAnswer = () => (dispatch, getState) => {
  const state = getState();
  const { currentAnswer } = state.exam;
  const answers = [...state.exam.answers, ...currentAnswer];
  localStorage.setItem('answers', JSON.stringify(answers));
  dispatch({
    type: examType1Constants.PUSH_ANSWER,
    answers,
  });
};

const clearTest = () => {
  localStorage.removeItem('exam');
  localStorage.removeItem('currentQuestion');
  localStorage.removeItem('answers');
  localStorage.removeItem('examBeginTime');
  return (dispatch) => {
    dispatch({ type: examType1Constants.CLEAR_EXAM });
  };
};

const clearAnswers = () => {
  localStorage.removeItem('currentQuestion');
  localStorage.removeItem('answers');
  return (dispatch) => {
    dispatch({ type: examType1Constants.CLEAR_EXAM_ANSWERS });
  };
};

const examType1Actions = {
  initTest,
  setMultiQuestionId,
  setCurrentQuestion,
  setCurrentAnswer,
  pushAnswer,
  clearTest,
  clearAnswers,
  setExamBeginTime,
};

export default examType1Actions;
